@import "src/theme/mixins.scss";
@import "src/theme/ngx-toastr.scss";
@include neumorphic-forms;

@font-face {
  font-family: "Avenir";
  src: url("assets/fonts/Avenir.ttc") format("truetype");
}
@font-face {
  font-family: "AvenirBold";
  src: url("assets/fonts/AvenirLTStd-Medium.otf");
}

.ant-form-item-has-success.ant-form-item-has-feedback
  .ant-form-item-children-icon {
  color: $primary;
}

.ant-form-item-has-error.ant-form-item-has-feedback
  .ant-form-item-children-icon {
  color: $secondary;
}

.transparent-autofill {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    font-family: "Avenir";
    -webkit-text-fill-color: white !important;
    transition: background-color 500000s;
  }
}

.toast-container {
  &.toast-top-center .ngx-toastr {
    width: 400px !important;
  }
  .toast-info,
  .toast-error {
    background-image: none;
  }
  .toast-info {
    background-color: $primary;
    box-shadow: 0 0 12px #12472d !important;
  }
  .toast-error {
    background-color: $secondary;
    box-shadow: 0 0 20px #8b204d !important;
  }
  button.toast-close-button,
  .toast-title,
  .toast-message {
    color: black;
    top: -1em;
  }
}
