$default: #344675 !default;
$primary: #3abeac !default;
$secondary: #ffa068 !default;
$success: #3abeac !default;
$danger: #ffa068 !default;
$dark-primary: #0d2925 !default;
$dark-secondary: #1a0900 !default;
$lightest-gray: #606060 !default;
$light-gray: #404040 !default;
$gray: #303030 !default;
$dark-gray: #202020 !default;
$near-black: #101010 !default;
$black: #000000 !default;

@mixin auth-pages {
  .container {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: $dark-gray;

    .content {
      width: 100%;
      height: 90vh;
      max-width: 560px;
      display: flex;
      padding: 0 16px;
      margin-bottom: 32px;
      background: $dark-gray;
      text-align: -webkit-center;
    }
  }

  .page-title {
    font-family: "AvenirBold";
    text-align: center;
    h1 {
      color: $light-gray;
      font-size: 70px;
    }
  }

  .soft-box {
    box-shadow: -1px -1px 10px rgba($lightest-gray, 1),
      2px 2px 15px rgba($black, 1), inset 0px 0px 10px rgba($lightest-gray, 1),
      inset 10px 10px 10px rgba($dark-gray, 1);
  }

  .form-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;

    .long-button {
      flex-basis: 0;
      flex-grow: 0.7;
    }
    .short-button {
      flex-basis: 0;
      flex-grow: 0.25;
    }
    .long-button,
    .short-button {
      min-height: 40px;
    }
  }
}

@mixin neumorphic-basic(
  $accent: $black,
  $accent-highlight: $light-gray,
  $offset: 0
) {
  .concave {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    // box-shadow: 9px 9px 16px rgba($gray, 0.6), -9px -9px 16px $gray;

    box-shadow: inset 5px 5px 7px 5px rgba($black, 0.4),
      inset -5px -5px 7px 6px rgba($gray, 0.6);
  }
  .soft-button {
    background: $dark-gray;
    border-radius: 20px;
    box-shadow: inset 0 0 15px rgba(55, 84, 170, 0),
      inset 0 0 20px rgba(255, 255, 255, 0), 7px 7px 15px $accent,
      -7px -7px 20px rgba($gray, 1), inset 0px 0px 4px rgba($gray, 0.6);
    &:hover {
      box-shadow: inset 7px 7px 15px rgba($gray, 0.15),
        inset -7px -7px 20px rgba($secondary, 0.2), 0px 0px 4px rgba($accent, 1);
    }
    transition: box-shadow 399ms ease-in-out;
  }
  .very-soft-button.enabled {
    &:hover {
      box-shadow: inset 0 0 25px rgba($black, 0.4),
        inset 0 0 20px rgba(255, 255, 255, 0), 7px 7px 15px $accent,
        -8px -8px 30px rgba($light-gray, 1), inset 0px 0px 4px rgba($gray, 0.1);
      border-radius: 15px;
      transform: translateX($offset);
    }
    &:active {
      transform: translateX(calc($offset * 2));
      transition: transform 99ms ease-in-out;
    }
  }
  .very-soft-button {
    border: none;
    background-color: $gray !important;
    border-radius: 20px;
    box-shadow: inset 0 0 25px rgba($black, 0),
      inset 0 0 20px rgba(255, 255, 255, 0), 7px 7px 15px $black,
      0px -5px 25px rgba($light-gray, 1), inset 0px 0px 4px rgba($gray, 0.1);
    transition: box-shadow 399ms ease-in-out, border-radius 399ms ease-in-out,
      transform 399ms ease-in-out;
  }

  .soft-text {
    top: 50%;
    left: 50%;
    text-shadow: -2px -2px 15px $light-gray, 2px 2px 12px $black;
    outline: none;
  }
}

@mixin neumorphic-forms {
  $soft-input-left: -3px -3px 20px $light-gray;
  $soft-input-left-retract: -1px -1px 20px $light-gray;
  $soft-input-right: 3px 3px 20px $black;
  $soft-input-right-retract: 5px 5px 20px $black;

  $soft-input-right-error: 3px 3px 20px $dark-secondary;
  $soft-input-left-error: 3px 3px 20px $secondary;

  $border-radius: 15px;

  input {
    font-family: "Avenir";
    height: 50px;
    width: 290px;
    appearance: none;
    box-shadow: $soft-input-left, $soft-input-right;
    border-radius: $border-radius;
    border: 8px solid $gray !important;
    background-color: $gray !important;
    cursor: pointer;
    outline: none;
    padding: 0px 15px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    color: white;
  }
  input:after {
    content: "\f011";
    color: #7a7a7a;
    font-size: 30px;
    color: $secondary;
  }

  input:focus {
    border: none;
    border-radius: 5px;
    box-shadow: $soft-input-left-retract, $soft-input-right,
      inset -10px 0px 15px rgba($light-gray, 0.5),
      inset 10px 10px 15px rgba($dark-gray, 0.5) !important;
    color: $primary;
  }

  input:focus:after {
    color: $primary;
  }
  .soft-shadow-error {
    box-shadow: $soft-input-left, $soft-input-right-error;
  }

  input.soft-shadow-error:focus {
    color: $danger;
  }

  .soft-toggle {
    --antd-wave-shadow-color: $light-gray;
    .ant-switch:focus:hover,
    .ant-switch {
      box-shadow: -1px -1px 10px rgba($lightest-gray, 1),
        2px 2px 15px rgba($black, 1), inset 0px 0px 10px rgba($lightest-gray, 1),
        inset 10px 10px 10px rgba($dark-gray, 1);

      .ant-switch-handle::before {
        background-color: $lightest-gray;
      }
    }

    .ant-switch-checked {
      background-color: $dark-primary;
      box-shadow: -1px -1px 10px rgba($lightest-gray, 1),
        1px 1px 10px rgba($black, 1), inset 0px 0px 10px rgba($lightest-gray, 1),
        inset 10px 10px 10px rgba($dark-primary, 1) !important;
    }
  }
}
